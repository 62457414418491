/*
 * File: reportWebVitals.js
 * Description: Utility function for reporting web vitals performance metrics.
 *              Imports the 'web-vitals' library and provides a function to call
 *              the necessary metrics functions (CLS, FID, FCP, LCP, TTFB).
 * Author: Grace Shi
 * Date: March 12, 2024
 */

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
