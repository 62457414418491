/*
 * File: QRCodeApp.js
 * Description: React component for generating QR codes based on user input.
 *              Manages a multi-step form to collect user information and displays
 *              a QR code with the encoded data. Uses React hooks for state management.
 * Author: Grace Shi
 * Date: March 12, 2024
 */
import React, { useState, useRef, useEffect } from "react";
import QRCode from "react-qr-code";
import "./style/QRCodeApp.css";

/**
 * QRCodeApp Component
 *
 * A React component for generating QR codes based on user input.
 *
 * @returns {JSX.Element} - React component representing the QRCodeApp.
 */
const QRCodeApp = () => {
  const LANGUAGES = [
    "Español",
    "Français",
    "中文",
    "한글",
    "日本語",
    "عَرَبِيّ",
    "हिन्दी",
  ];
  const LANGUAGE_MAPPING = {
    Español: "Spanish",
    Français: "French",
    中文: "Chinese",
    한글: "Korean",
    日本語: "Japanese",
    عَرَبِيّ: "Arabic",
    हिन्दी: "Hindi",
  };
  const DESTINATIONS = [
    {
      value: "Mexico City",
      flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/2000px-Flag_of_Mexico.svg.png",
    },
    {
      value: "Tokyo",
      flag: "https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/1200px-Flag_of_Japan.svg.png",
    },
    {
      value: "Paris",
      flag: "https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/800px-Flag_of_France.svg.png",
    },
    {
      value: "Seoul",
      flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/800px-Flag_of_South_Korea.svg.png",
    },
  ];
  const ERROR_MESSAGES = [
    "Please",
    "Don't forget to",
    "Remember to",
    "Go ahead and",
    "Now's the time to",
    "This is where you",
  ];

  // User input
  const [name, setName] = useState("");
  const [language, setLanguage] = useState("");
  const [destination, setDestination] = useState("");
  const [qrData, setQRData] = useState("");

  // Page management
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");

  // Ref for QRCode element
  const qrCodeRef = useRef(null);

  // Event handler for name input change
  const handleNameChange = (event) => {
    setName(event.target.value.replace(/[^A-Za-z\s-]/gi, ""));
  };

  // Event handler for language selection change
  const handleLanguageChange = (event) => {
    setLanguage(event.currentTarget.value);
  };

  // Event handler for destination selection change
  const handleDestinationChange = (event) => {
    setDestination(event.currentTarget.value);
  };

  // Function to trigger a QR code generation based on user input
  const generateQRCode = () => {
    if (destination === "") {
      setError(ERROR_MESSAGES[Math.floor(Math.random() * (5 - 0 + 1))]);
    } else {
      setError("");
      setQRData(
        name + "0" + LANGUAGE_MAPPING[language] + "0" + destination + "0"
      );
      setStep(step + 1);
    }
  };

  // Back action handler
  const goBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleStep1Next = () => {
    if (name === "") {
      setError(ERROR_MESSAGES[Math.floor(Math.random() * (5 - 0 + 1))]);
    } else {
      setError("");
      setStep(step + 1);
    }
  };

  const handleStep2Next = () => {
    if (language === "") {
      setError(ERROR_MESSAGES[Math.floor(Math.random() * (5 - 0 + 1))]);
    } else {
      setError("");
      setStep(step + 1);
    }
  };

  // To fix the React delay
  useEffect(() => {
    console.log("Updated:", name + language + destination);
    console.log(step)
  }, [name, language, destination, step]);

  return (
    <div className={`container ${"step-" + step.toString()}`}>
      <div className="topLeftButton">
        {step > 0 && (
          <button type="button" className="back-button" onClick={goBack}>
            Back
          </button>
        )}
      </div>
      {step === 0 && (
        <div>
          <img
            src="https://www.misappliedsciences.com/content/img/logo.png"
            alt="Your Alt Text"
            className="centered-image"
          />
          <div className="titleLabel">Three things to get started!</div>
        </div>
      )}
      {/* Input fields for user data */}
      {step === 1 && (
        <div className="step1-container">
          {error && <div className="input-errorMessage">{error}</div>}
          <div className="titleLabel">Enter your name</div>
          <div className="textInput-container">
            <input
              id="name"
              type="text"
              name="text-name"
              value={name}
              onChange={handleNameChange}
              className="textInput"
            />
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="step2-container">
          {error && <div className="input-errorMessage">{error}</div>}
          <div className="titleLabel">Pick a second language</div>
          <div className="button-stack">
            {LANGUAGES.map((languageOption) => (
              <button
                key={languageOption}
                type="button"
                className={
                  languageOption === language
                    ? "selected-stack-button"
                    : "stack-button"
                }
                value={languageOption}
                onClick={(e) => {
                  handleLanguageChange(e);
                }}
              >
                {languageOption}
              </button>
            ))}
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="step3-container">
          {error && <div className="input-errorMessage">{error}</div>}
          <div className="titleLabel">Pick a destination</div>
          <div className="button-grid">
            {DESTINATIONS.map((destinationOption) => (
              <button
                key={destinationOption.value}
                type="button"
                className={
                  destinationOption.value === destination
                    ? "selected-grid-button"
                    : "grid-button"
                }
                value={destinationOption.value}
                onClick={(e) => {
                  handleDestinationChange(e);
                }}
              >
                <img
                  src={destinationOption.flag}
                  alt={`${destinationOption.value} Country Flag`}
                  className="flag-icon"
                />
                {destinationOption.value}
              </button>
            ))}
          </div>
        </div>
      )}
      {step === 0 && (
        <button
          type="button"
          className="submit-button"
          onClick={(e) => {
            setStep(step + 1);
          }}
        >
          Start
        </button>
      )}
      {step === 1 && (
        <button
          type="button"
          className="submit-button"
          onClick={handleStep1Next}
        >
          Next
        </button>
      )}
      {step === 2 && (
        <button
          type="button"
          className="submit-button"
          onClick={handleStep2Next}
        >
          Next
        </button>
      )}
      {/* Button to trigger QR code generation */}
      {step === 3 && (
        <button
          type="button"
          className="submit-button"
          onClick={generateQRCode}
        >
          Finish
        </button>
      )}
      {step === 4 && (
        <div className="qr-code-container">
          {qrData && (
            <QRCode className="qr-code" value={qrData} ref={qrCodeRef} />
          )}
          <div className="titleLabel">Scan me!</div>
        </div>
      )}
    </div>
  );
};

export default QRCodeApp;
