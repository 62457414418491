/*
 * File: GameApp.js
 * Description: React component representing the touchscreen game interface.
 *              Handles user input, including buttons and sliders, and communicates
 *              with the backend to update the game state.
 * Author: Grace Shi
 * Date: March 12, 2024
 */
import React, { useState, useEffect, useRef } from "react";
import "./style/GameApp.css";
import triangle_01 from "./assets/triangle_01.svg";
import triangle_02 from "./assets/triangle_02.svg";
import right_arrow from "./assets/stemless_right_arrow.svg";
import misapplied_reality_remote_text from "./assets/misapplied_reality_remote_text.png";

/**
 * GameApp Component for touchscreen
 *
 * @param {number} id - User ID.
 * @param {number} sessionId - Session ID.
 * @returns {JSX.Element} - React component representing the GameApp.
 */
function GameApp({ id, sessionId }) {
  const CORS_BYPASS = ""; // CORS Bypass URL for cross-origin requests, if CORS issue try "cors-anywhere.herokuapp.com/"
  const BACKEND_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BACKEND_URL || "https://qr.misappsci.com:5002"
      : // : "http://192.168.1.68:5002"; // local
        "https://qr.misappsci.com:5002"; // local office
        // "http://10.0.0.11:5002"; // local (farm)
  const BACKEND_USER_ENDPOINT = "/user";
  const SLIDER_RANGE = 100;
  const SLIDER_HEIGHT = 380;
  const SLIDER_THUMB_HEIGHT = 150;
  const SLIDER_THUMB_DEFAULT_HEIGHT = 25;

  // State variables to store user input
  const [userName, setUserName] = useState("");
  const [upButton, setUpButton] = useState(false);
  const [downButton, setDownButton] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [orientation, setOrientation] = useState(() =>
    window.matchMedia("(orientation: portrait)").matches
      ? "portrait"
      : "landscape"
  );
  const [heading, setHeading] = useState(0);

  // State variables to track user interaction
  const [buttonMouseUps, setButtonMouseUps] = useState(0);
  const [buttonMouseDowns, setButtonMouseDowns] = useState(0);
  const [thumbPosition, setThumbPosition] = useState(0); // for the slider thumb cover

  // Effect hook to prevent default touch behavior on buttons
  useEffect(() => {
    const preventDefault = (event) => {
      event.preventDefault();
    };

    const buttons = document.querySelectorAll(".button");

    buttons.forEach((button) => {
      button.addEventListener("touchstart", preventDefault, { passive: false });
    });

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("touchstart", preventDefault);
      });
    };
  }, []);

  // Effect hook to send new user input to the backend via POST request
  useEffect(() => {
    fetch(CORS_BYPASS + BACKEND_URL + BACKEND_USER_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        sessionId: sessionId,
        name: userName,
        upButton: upButton.toString(),
        downButton: downButton.toString(),
        sliderValue: sliderValue / SLIDER_RANGE,
        orientation: orientation,
        heading: heading,
      }),
    });
  }, [
    sliderValue,
    buttonMouseUps,
    buttonMouseDowns,
    userName,
    orientation,
    heading,
  ]);

  // Add event listener for orientation change
  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(
        window.matchMedia("(orientation: portrait)").matches
          ? "portrait"
          : "landscape"
      );
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  // Listener for heading (compass)
  useEffect(() => {
    const handleOrientationChange = (event) => {
      if (event.webkitCompassHeading) {
        setHeading(event.webkitCompassHeading);
      } else {
        setHeading(null);
      }
    };
    window.addEventListener(
      "deviceorientationabsolute",
      handleOrientationChange,
      true
    );

    // return () => {
    //   window.removeEventListener("deviceorientation", handleOrientationChange);
    // };
  }, []);

  // Handler for button mouse up/touch end events
  const handleButtonMouseUp = () => {
    setUpButton(false);
    setDownButton(false);
    setButtonMouseUps(buttonMouseUps + 1);
  };

  // Handler for button mouse down/touch start events
  const handleButtonMouseDown = (value) => {
    setUpButton(value);
    setDownButton(!value);
    setButtonMouseDowns(buttonMouseDowns + 1);
  };

  // Handler for name input change
  const handleNameChange = (value) => {
    setUserName(value);
  };

  // Handler for slider input change
  const handleSliderChange = (event) => {
    const sliderValue = parseFloat(event.target.value);
    const thumbPosition =
      ((SLIDER_HEIGHT - SLIDER_THUMB_HEIGHT) / SLIDER_RANGE) * sliderValue;
    setSliderValue(sliderValue);
    setThumbPosition(thumbPosition);
  };

  // Ref for button element
  // const buttonRef = useRef(null);

  return (
    <div className="remote-container">
      <div className="game-container">
        <div className="input-container">
          {/* {"DeviceMotionEvent" in window &&
          typeof DeviceMotionEvent.requestPermission === "function" && (
            <button
              onClick={() => {
                // For iOS 13+, needs HTTPS
                const handleOrientationChange = (event) => {
                  if (event.webkitCompassHeading) {
                    setHeading(event.webkitCompassHeading);
                  } else {
                    setHeading(null);
                  }
                };
                DeviceOrientationEvent.requestPermission()
                  .then((response) => {
                    if (response === "granted") {
                      window.addEventListener(
                        "deviceorientation",
                        handleOrientationChange,
                        true
                      );
                    } else {
                      alert("has to be allowed!");
                    }
                  })
                  .catch(() => alert("not supported"));
              }}
            >
              Enable device orientation
            </button>
          )} */}
          <input
            type="text"
            className="text-input"
            placeholder="Enter name here"
            onChange={(e) => handleNameChange(e.target.value)}
          />
          {/* Button to hide keyboard on mobile */}
          {/* <button
          className="keyboard-hide-button"
          onClick={() => document.activeElement.blur()}
        >
          <img
            src={right_arrow}
            alt="Hide Keyboard"
            className="rightArrowImage"
          />
        </button> */}
        </div>
        <br />
        <div className="controls-container">
          <div className="button-container">
            {/* Up button, touch screen */}
            <button
              className="button no-shadow"
              onTouchStart={() => handleButtonMouseDown(true)}
              onTouchEnd={() => handleButtonMouseUp(true)}
            >
              {!upButton ? (
                <img src={triangle_01} alt="Up" className="upTriangleImage" />
              ) : (
                <img src={triangle_02} alt="Up" className="upTriangleImage" />
              )}
            </button>

            {/* Down button, touch screen */}
            <button
              className="button no-shadow"
              onTouchStart={() => handleButtonMouseDown(false)}
              onTouchEnd={() => handleButtonMouseUp(false)}
            >
              {!downButton ? (
                <img
                  src={triangle_01}
                  alt="Down"
                  className="downTriangleImage"
                />
              ) : (
                <img
                  src={triangle_02}
                  alt="Down"
                  className="downTriangleImage"
                />
              )}
            </button>
          </div>
          {/* Slider */}
          <div className="vertical-slider-container">
            {/* Styled slider thumb cover */}
            <div
              className="vertical-slider-thumb"
              style={{ bottom: `${thumbPosition}px` }}
            ></div>
            <input
              type="range"
              min="0"
              max={SLIDER_RANGE.toString()}
              value={sliderValue}
              onChange={handleSliderChange}
              className="vertical-slider"
            />
          </div>
        </div>
      </div>
      <div className="logo-container">
        <img src={misapplied_reality_remote_text} alt="Misapplied Reality" className="logo-image" />
      </div>
    </div>
  );
}

export default GameApp;
