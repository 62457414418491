/*
 * File: App.js
 * Description: Main component for handling the initial rendering logic.
 *              Retrieves 'id' and 's-id' from URL parameters and renders
 *              either the GameApp or QRCodeApp component based on the presence of 'id'.
 * Author: Grace Shi
 * Date: March 12, 2024
 */
import React, { useState, useEffect } from "react";
import "./style/index.css";
import GameApp from "./GameApp.js";
import QRCodeApp from "./QRCodeApp.js";

function App() {
  const [id, setId] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  // Effect hook to get id and s-id
  useEffect(() => {
    const idParam = new URLSearchParams(window.location.search).get("id");
    // Check if 'id' is present and is a valid number
    if (idParam && /^\d+$/.test(idParam)) {
      setId(Number(idParam));
    }

    const sessionIdParam = new URLSearchParams(window.location.search).get(
      "s-id"
    );
    // Check if 's-id' is present and is a valid number
    if (sessionIdParam && /^\d+$/.test(sessionIdParam)) {
      setSessionId(Number(sessionIdParam));
    }
  }, []);
    

  // If 'id' is not present, render the QRCodeApp component
  if (!id && id !== 0) {
    return <QRCodeApp />;
  }
  // If 'id' is present, render the GameApp component with 'id' and 'sessionId' props
  else {
    return <GameApp id={id} sessionId={sessionId} />;
  }
}

export default App;
